import Model from '~/models/Model'

export default class Skill extends Model {
  static get resource () {
    return 'skills'
  }

  static search (term) {
    return this.axios().get('search?q=' + term)
  }
}
