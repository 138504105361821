<template>
	<el-select ref="skillsSelect" v-model="localValue" class="w-100" multiple filterable remote placeholder="Type a Skill to Search" :remote-method="search" :loading="searching">
		<el-option v-for="item in skills" :key="item.skill_id" :label="item.skill_name" :value="item.skill_id" />
	</el-select>
</template>

<script>
import Skill from '~/models/Skills/Skill';
import handleError from '~/helpers/handleError';

export default {
	props: {
		value: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
			searching: false,
			skills: []
		};
	},

	computed: {
		localValue: {
			get() {
				const value = this.value;
				if (!value) {
					return [];
				}

				if (value.length > 0 && value[0].skill_id) {
					return value.map(skill => skill.skill_id);
				}

				return value;
			},

			set(value) {
				this.$emit('input', value);
			}
		}
	},

	watch: {
		value(newValue) {
			if (newValue.length > 0 && newValue[0].skill_id) {
				this.skills = newValue;
			}
			this.$refs.skillsSelect.blur();
		}
	},

	methods: {
		search(query) {
			if (!query) {
				return;
			}
			this.searching = true;
			Skill.search(query)
				.then(response => {
					this.searching = false;
					// only show the top 50 to prevent hanging
					this.skills = response.data.data.slice(0, 50);
				})
				.catch(error => {
					this.searching = false;
					handleError(error);
				});
		}
	}
};
</script>
