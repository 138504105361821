<template>
	<dashboard>
		<div v-if="isOnFreePlan">
			<el-alert :closable="false" class="mb-3" title="You are currently on the free plan">
				Upgrade now so you can see the freelancers' details and contact them directly.
				<router-link to="/employer/billing">
					<el-button size="mini" class="block mt-2 mb-1 ml-2" type="warning">Upgrade now</el-button>
				</router-link>
			</el-alert>
		</div>
		<!-- <div class="ml-1">
      <span class="mobile-links">
        <router-link to="/employer/starredfreelancers" class="no-underline-hover" exact>
          <span class="float-right mt-1 ml-3 bold">
            Bookmarked (0)</span>
        </router-link>
        <router-link to="/employer/hiredfreelancers" class="no-underline-hover" exact>
          <span class="float-right mt-1 bold">
            Hired (0)</span>
        </router-link>
      </span> 
    </div>-->

		<el-row :gutter="15">
			<el-col :xs="24">
				<div class="jobfeed">
					<el-card class="job">
						<h4 class="m-3 bold orange-text">Freelancer Search</h4>
						<hr class="" />
						<div class="search">
							<el-input type="search" v-model="filters.keywords" placeholder=""> </el-input>
							<div class="mt-3">
								<el-row :gutter="30">
									<el-col :xs="24" :md="10">
										<el-row>
											<el-col :span="24" class="mb-3">
												<p class="mb-2 small">Skills</p>
												<skills-multi-select v-model="filters.skills" />
											</el-col>
										</el-row>
									</el-col>
									<el-col :xs="24" :md="14" class="type">
										<p class="mb-2 small">Type</p>
										<div v-for="(rateTypeLabel, rt) in rateTypes" :key="rt" class="mb-3 input-group">
											<div class="input-group-prepend">
												<div class="input-group-text">
													<input v-model="filters.rates[rt].enabled" type="checkbox" class="type-box" />
													<p class="mb-0 ml-2 small" v-text="rateTypeLabel" />
												</div>
											</div>
											<input v-model="filters.rates[rt].min" type="number" class="form-control form-number" :class="{ 'is-invalid': errors[`rates.${rt}.min`] }" placeholder="Min." :disabled="!filters.rates[rt].enabled" @input="clearError(`rates.${rt}.min`)" />
											<input v-model="filters.rates[rt].max" type="number" class="form-control form-number" :class="{ 'is-invalid': errors[`rates.${rt}.min`] }" placeholder="Max" :disabled="!filters.rates[rt].enabled" @input="clearError(`rates.${rt}.min`)" />
											<div class="input-group-append">
												<span class="input-group-text" id="usd">USD</span>
											</div>
										</div>
										<!-- <div class="mb-3 input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <input v-model="filters.rates.full_time.enabled" type="checkbox" class="type-box"> <p class="mb-0 ml-2 small">Full time</p>
                        </div>
                      </div>
                      <input v-model="filters.rates.full_time.min" type="number" class="form-control" placeholder="Min." :disabled="! filters.rates.full_time.enabled">
                      <input v-model="filters.rates.full_time.max" type="number" class="form-control" placeholder="Max" :disabled="! filters.rates.full_time.enabled">
                    </div>
                    <div class="mb-3 input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <input v-model="filters.rates.part_time.enabled" type="checkbox"> <p class="mb-0 ml-2 small">Part time</p>
                        </div>
                      </div>
                      <input v-model="filters.rates.part_time.min" type="number" class="form-control" placeholder="Min." :disabled="! filters.rates.part_time.enabled">
                      <input v-model="filters.rates.part_time.max" type="number" class="form-control" placeholder="Max" :disabled="! filters.rates.part_time.enabled">
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <input v-model="filters.rates.hourly.enabled" type="checkbox"> <p class="mb-0 ml-2 small">Hourly</p>
                        </div>
                      </div>
                      <input v-model="filters.rates.hourly.min" type="number" class="form-control" placeholder="Min." :disabled="! filters.rates.hourly.enabled">
                      <input v-model="filters.rates.hourly.max" type="number" class="form-control" placeholder="Max" :disabled="! filters.rates.hourly.enabled">
                    </div> -->
										<!-- <el-col :span="6" :sm="5" :md="5">
                        <el-checkbox label="Hourly"></el-checkbox>
                      </el-col>
                      <el-col :span="18" :md="19">
                        <el-input-number v-model="num" :precision="2" :step="0.1" :controls="false" size="small"></el-input-number>
                        <el-input-number v-model="num" :precision="2" :step="0.1" :controls="false" size="small" class="ml-3"></el-input-number>
                      </el-col> -->
									</el-col>
								</el-row>
							</div>
						</div>
						<hr class="mb-3" />
						<div class="body">
							<div class="mx-3">
								<p class="text-right small">Displaying {{ freelancers.length }} out of {{ totalResults }} freelancers</p>
							</div>
						</div>
					</el-card>
				</div>

				<div class="freelancer-profile">
					<el-card v-for="freelancer in freelancers" :key="freelancer.freelancer_id">
						<router-link :to="{ path: '/employer/profile/' + freelancer.freelancer_id }" exact class="no-underline-hover">
							<el-row :gutter="15" type="flex">
								<el-col :xs="6" :md="3">
									<img :src="profilePhotoUrl(freelancer)" class="mt-2 mb-2 w-75" />
								</el-col>
								<el-col :xs="15" :md="18" class="align-self-center">
									<p class="mb-1 bold small" :class="{ blur: freelancer.blurred }">{{ freelancer.first_name }} {{ freelancer.last_name }}</p>
									<p v-if="freelancer.profile" class="mb-1 small">
										<span class="orange-text">{{ freelancer.profile.title }}</span>
									</p>
									<!-- <el-rate
                    v-model="value"
                    disabled
                  /> -->
									<div class="hidden-xs">
										<p class="mt-1 mb-2 small">
											<span v-if="freelancer.profile">{{ freelancer.profile.intro }}</span>
										</p>

										<el-tag v-for="skill in freelancer.skill" :key="skill.skill_id" class="mb-1 mr-1" type="info">{{ skill.skill_name }}</el-tag>

										<!-- <el-tag type="info">Vue.js</el-tag> <el-tag type="info">PHP</el-tag> -->
									</div>
								</el-col>
								<el-col :span="3" class="text-center text-lg-right">
									<el-button type="warning" size="small" class="" plain circle icon="el-icon-more" />
								</el-col>
							</el-row>
							<el-row class="visible-xs">
								<el-col :span="24">
									<p class="mt-2 mb-2 small">
										<span v-if="freelancer.profile">{{ freelancer.profile.intro }}</span>
									</p>
									<el-tag v-for="skill in freelancer.skill" :key="skill.skill_id" type="info" class="mt-1 mr-1">{{ skill.skill_name }}</el-tag>

									<!-- <el-tag type="info">Vue.js</el-tag> <el-tag type="info">PHP</el-tag> -->
								</el-col>
							</el-row>
						</router-link>
					</el-card>
					<!--  <el-card>
            <el-row :gutter="15" type="flex">
              <el-col :span="3">
                <img src="/images/userimage.svg" class="w-100">
              </el-col>
              <el-col :span="18" class="align-self-center">
                <p class="mb-1 bold small">Jane Doe</p>
                <el-rate
                    v-model="value"
                    disabled>
                </el-rate>
                <p class="mt-1 mb-2 small"><span class="bold gray-text">Intro:</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae pretium eros. </p>
                <p class="mb-2 small"><span class="bold gray-text">Skills:</span></p>
                <el-tag type="info">Laravel</el-tag> <el-tag type="info">Vue.js</el-tag> <el-tag type="info">PHP</el-tag>
              </el-col>
              <el-col :span="3" class="text-center text-lg-right">
                <el-button type="warning" size="small" class="" plain circle icon="el-icon-more"></el-button>
              </el-col>
            </el-row>
          </el-card>
          <el-card>
            <el-row :gutter="15" type="flex">
              <el-col :span="3">
                <img src="/images/userimage.svg" class="w-100">
              </el-col>
              <el-col :span="18" class="align-self-center">
                <p class="mb-1 bold small">Jane Doe</p>
                <el-rate
                    v-model="value"
                    disabled>
                </el-rate>
                <p class="mt-1 mb-2 small"><span class="bold gray-text">Intro:</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae pretium eros. </p>
                <p class="mb-2 small"><span class="bold gray-text">Skills:</span></p>
                <el-tag type="info">Laravel</el-tag> <el-tag type="info">Vue.js</el-tag> <el-tag type="info">PHP</el-tag>
              </el-col>
              <el-col :span="3" class="text-center text-lg-right">
                <el-button type="warning" size="small" class="" plain circle icon="el-icon-more"></el-button>
              </el-col>
            </el-row>
          </el-card> -->
					<el-card>
						<div v-if="totalResults > perPage" class="mb-0 text-center job-pagination">
							<el-pagination background :current-page.sync="filters.page" layout="prev, pager, next" :page-size="perPage" @current-change="pageChange" :total="totalResults" :pager-count="5" small />
						</div>
					</el-card>
				</div>
			</el-col>
		</el-row>
	</dashboard>
</template>

<script>
import _ from 'lodash';
// import Cleave from 'cleave.js'
import Dashboard from '~/components/Dashboard/Dashboard';
import SkillsMultiSelect from '~/components/Skills/MultiSelect';
import Freelancer from '~/models/Freelancer';
import handleError from '~/helpers/handleError';
import showsProfilePhoto from '~/mixins/showsProfilePhoto';
import checksIfOnFreePlan from '~/mixins/checksIfOnFreePlan';

export default {
	components: { Dashboard, SkillsMultiSelect },

	mixins: [showsProfilePhoto, checksIfOnFreePlan],

	// directives: {
	//   cleave: {
	//     inserted: (el, binding) => {
	//       el.cleave = new Cleave(el, binding.value || {})
	//     },
	//     update: (el) => {
	//         const event = new Event('input', {bubbles: true});
	//         setTimeout(function () {
	//             el.value = el.cleave.properties.result
	//             el.dispatchEvent(event)
	//         }, 100);
	//     }
	//   }
	// },

	data() {
		return {
			value: 5,
			rateTypes: {
				full_time: 'Full time',
				part_time: 'Part time',
				hourly: 'Hourly'
			},
			filters: {
				page: 1,
				keywords: '',
				rates: {
					full_time: {
						enabled: false,
						min: '',
						max: ''
					},
					part_time: {
						enabled: false,
						min: '',
						max: ''
					},
					hourly: {
						enabled: false,
						min: '',
						max: ''
					}
				},
				skills: []
			},
			freelancers: [],
			totalResults: 0,
			errors: {},
			perPage: 1
		};
	},

	watch: {
		'filters.keywords': function(value) {
			let keywordsLength = value.trim().length;
			if (!keywordsLength || keywordsLength > 2) {
				this.search();
			}
		},
		'filters.skills': function() {
			this.search();
		},
		'filters.rates': {
			deep: true,
			handler() {
				this.search();
			}
		}
		// filters: {
		// 	deep: true,
		// 	handler() {
		// 		//this.search();
		// 	}
		// }
	},

	created() {
		if (this.$route.query.page) {
			this.filters.page = parseInt(this.$route.query.page);
		}
		this.search();
	},

	methods: {
		clearError(path) {
			this.$delete(this.errors, path);
		},

		pageChange(page) {
			window.history.pushState(null, null, `?page=${page}`);
			this.search();
		},

		search: _.debounce(function() {
			Freelancer.search(this.filters)
				.then(({ data }) => {
					this.freelancers = data.data;
					this.totalResults = data.meta.total;
					this.perPage = data.meta.per_page;
				})
				.catch(error => {
					if (!error.response) {
						return handleError(error);
					}

					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					}
				});
		}, 800)
	}
};
</script>

<style lang="scss" scoped>
.form-number {
	height: 34px;
	line-height: 34px;
}
.el-alert {
	background-color: #fdf6ec;
	border: 1px solid #e87622;
}
.el-alert >>> .el-alert__description {
	color: #333;
	margin: 0;
	width: 100%;
}
.el-alert--info.is-light,
.d-block {
	color: #333;
}
@media (max-width: 500px) {
	.mobile-links {
		display: block;
		margin-top: 5px;
		margin-bottom: -10px;

		.float-right {
			float: none !important;
			margin-right: 10px;
			margin-left: 0 !important;
		}
	}
}
</style>

<style lang="scss">
.jobfeed {
	.search {
		padding: 30px;
	}
	.el-input-group__append button.el-button,
	.el-input-group__append,
	.el-input-group__prepend {
		color: #fff;
		background-color: #e87722;
		border-color: #e87722;
	}
	.input-group-append .input-group-text {
		font-size: 80%;
	}
	.el-pagination {
		text-align: center;
	}
}
.type {
	.form-control {
		font-size: 12px;
		height: 34px;
		// padding-top: 1rem;
		// padding-bottom: 1rem;
	}
	.form-control:focus,
	.form-control:active {
		border-color: coral;
		box-shadow: none;
	}
}
@media (max-width: 414px) {
	.el-select-dropdown.el-popper.is-multiple {
		width: 93%;
		left: 15px !important;
	}
}
</style>
